import { defineStore } from 'pinia';
import tinycolor from 'tinycolor2';
import { omit } from 'lodash';
// import { slides } from '@/mocks/slides'
import { theme } from '@/mocks/theme';
import { layouts } from '@/mocks/layout';
import { usePPTStore } from './ppt';
export const useSlidesStore = defineStore('slides', {
    state: () => ({
        fileName: '',
        theme: theme,
        slides: [],
        slideIndex: 0,
        viewportRatio: 0.5625,
        slideType: 1, // 幻灯片类型 默认封面
    }),
    getters: {
        currentSlide(state) {
            return state.slides[state.slideIndex];
        },
        currentSlideAnimations(state) {
            const currentSlide = state.slides[state.slideIndex];
            if (!currentSlide?.animations)
                return [];
            const els = currentSlide.elements;
            const elIds = els.map(el => el.id);
            return currentSlide.animations.filter(animation => elIds.includes(animation.elId));
        },
        // 格式化的当前页动画
        // 将触发条件为“与上一动画同时”的项目向上合并到序列中的同一位置
        // 为触发条件为“上一动画之后”项目的上一项添加自动向下执行标记
        formatedAnimations(state) {
            const currentSlide = state.slides[state.slideIndex];
            if (!currentSlide?.animations)
                return [];
            const els = currentSlide.elements;
            const elIds = els.map(el => el.id);
            const animations = currentSlide.animations.filter(animation => elIds.includes(animation.elId));
            const formatedAnimations = [];
            for (const animation of animations) {
                if (animation.trigger === 'click' || !formatedAnimations.length) {
                    formatedAnimations.push({ animations: [animation], autoNext: false });
                }
                else if (animation.trigger === 'meantime') {
                    const last = formatedAnimations[formatedAnimations.length - 1];
                    last.animations = last.animations.filter(item => item.elId !== animation.elId);
                    last.animations.push(animation);
                    formatedAnimations[formatedAnimations.length - 1] = last;
                }
                else if (animation.trigger === 'auto') {
                    const last = formatedAnimations[formatedAnimations.length - 1];
                    last.autoNext = true;
                    formatedAnimations[formatedAnimations.length - 1] = last;
                    formatedAnimations.push({ animations: [animation], autoNext: false });
                }
            }
            return formatedAnimations;
        },
        layouts(state) {
            const { themeColor, fontColor, fontName, backgroundColor, } = state.theme;
            const subColor = tinycolor(fontColor).isDark() ? 'rgba(230, 230, 230, 0.5)' : 'rgba(180, 180, 180, 0.5)';
            const layoutsString = JSON.stringify(layouts)
                .replaceAll('{{themeColor}}', themeColor)
                .replaceAll('{{fontColor}}', fontColor)
                .replaceAll('{{fontName}}', fontName)
                .replaceAll('{{backgroundColor}}', backgroundColor)
                .replaceAll('{{subColor}}', subColor);
            return JSON.parse(layoutsString);
        },
    },
    actions: {
        setFileName(fileName) {
            this.fileName = fileName;
        },
        setTheme(themeProps) {
            this.theme = { ...this.theme, ...themeProps };
        },
        setViewportRatio(viewportRatio) {
            this.viewportRatio = viewportRatio;
        },
        setSlideType(slideType) {
            this.slideType = slideType;
        },
        setSlides(slides) {
            this.slides = slides;
            usePPTStore()._setSlides(slides);
        },
        addSlide(slide) {
            const slides = Array.isArray(slide) ? slide : [slide];
            const addIndex = this.slideIndex + 1;
            this.slides.splice(addIndex, 0, ...slides);
            this.slideIndex = addIndex;
            usePPTStore()._setSlides(this.slides);
        },
        updateSlide(props) {
            const slideIndex = this.slideIndex;
            this.slides[slideIndex] = { ...this.slides[slideIndex], ...props };
            usePPTStore()._setSlides(this.slides);
        },
        deleteSlide(slideId) {
            const slidesId = Array.isArray(slideId) ? slideId : [slideId];
            const deleteSlidesIndex = [];
            for (let i = 0; i < slidesId.length; i++) {
                const index = this.slides.findIndex(item => item.id === slidesId[i]);
                deleteSlidesIndex.push(index);
            }
            let newIndex = Math.min(...deleteSlidesIndex);
            const maxIndex = this.slides.length - slidesId.length - 1;
            if (newIndex > maxIndex)
                newIndex = maxIndex;
            this.slideIndex = newIndex;
            this.slides = this.slides.filter(item => !slidesId.includes(item.id));
            usePPTStore()._setSlides(this.slides);
        },
        updateSlideIndex(index) {
            this.slideIndex = index;
        },
        addElement(element) {
            const elements = Array.isArray(element) ? element : [element];
            const currentSlideEls = this.slides[this.slideIndex].elements;
            const newEls = [...currentSlideEls, ...elements];
            this.slides[this.slideIndex].elements = newEls;
            usePPTStore()._setSlides(this.slides);
        },
        deleteElement(elementId) {
            const elementIdList = Array.isArray(elementId) ? elementId : [elementId];
            const currentSlideEls = this.slides[this.slideIndex].elements;
            const newEls = currentSlideEls.filter(item => !elementIdList.includes(item.id));
            this.slides[this.slideIndex].elements = newEls;
            usePPTStore()._setSlides(this.slides);
        },
        updateElement(data) {
            const { id, props } = data;
            const elIdList = typeof id === 'string' ? [id] : id;
            const slideIndex = this.slideIndex;
            const slide = this.slides[slideIndex];
            const elements = slide.elements.map(el => {
                return elIdList.includes(el.id) ? { ...el, ...props } : el;
            });
            this.slides[slideIndex].elements = elements;
            usePPTStore()._setSlides(this.slides);
        },
        removeElementProps(data) {
            const { id, propName } = data;
            const propsNames = typeof propName === 'string' ? [propName] : propName;
            const slideIndex = this.slideIndex;
            const slide = this.slides[slideIndex];
            const elements = slide.elements.map(el => {
                return el.id === id ? omit(el, propsNames) : el;
            });
            this.slides[slideIndex].elements = elements;
            usePPTStore()._setSlides(this.slides);
        },
    },
});
