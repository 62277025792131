/**
 * 获取图片的base64
 * @param src 图片地址
 */
export const getImageData = (src) => {
    if (src.indexOf('data:image') !== -1) {
        return Promise.resolve(src);
    }
    src = src.replace('https://ai-writemall-com.oss-ap-southeast-1.aliyuncs.com/', 'https://img-writemall-com.oss-cn-shenzhen.aliyuncs.com/');
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', src, true);
        xhr.responseType = 'blob';
        xhr.onloadend = function () {
            if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
                const blob = this.response;
                const fileReader = new FileReader();
                fileReader.onloadend = function (e) {
                    const base64 = e.target.result;
                    resolve(base64);
                };
                fileReader.onerror = (err) => {
                    console.error(`fileReader.onerror `, err);
                    reject(Error('Network Error'));
                };
                fileReader.readAsDataURL(blob);
            }
        };
        xhr.onerror = (err) => {
            console.error('xhr.onerror', err);
            reject(Error('Network Error'));
        };
        xhr.send();
    });
};
/**
 * 获取图片的原始宽高
 * @param src 图片地址
 */
export const getImageSize = (src) => {
    return new Promise(resolve => {
        const img = document.createElement('img');
        img.src = src;
        img.style.opacity = '0';
        document.body.appendChild(img);
        img.onload = () => {
            const imgWidth = img.clientWidth;
            const imgHeight = img.clientHeight;
            img.onload = null;
            img.onerror = null;
            document.body.removeChild(img);
            resolve({ width: imgWidth, height: imgHeight });
        };
        img.onerror = () => {
            img.onload = null;
            img.onerror = null;
        };
    });
};
/**
 * 读取图片文件的dataURL
 * @param file 图片文件
 */
export const getImageDataURL = (file) => {
    return new Promise(resolve => {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            resolve(reader.result);
        });
        reader.readAsDataURL(file);
    });
};
