import Dexie from 'dexie';
const databaseNamePrefix = 'PPTist';
// 删除失效/过期的数据库
// 应用关闭时（关闭或刷新浏览器），会将其数据库ID记录在 localStorage 中，表示该ID指向的数据库已失效
// 当应用初始化时，检查当前所有数据库，将被记录失效的数据库删除
// 另外，距离初始化时间超过12小时的数据库也将被删除（这是为了防止出现因意外未被正确删除的库）
export const deleteDiscardedDB = async () => {
    // const now = new Date().getTime()
    // const localStorageDiscardedDB = localStorage.getItem(LOCALSTORAGE_KEY_DISCARDED_DB)
    // const localStorageDiscardedDBList: string[] = localStorageDiscardedDB ? JSON.parse(localStorageDiscardedDB) : []
    // const databaseNames = await Dexie.getDatabaseNames()
    // const discardedDBNames = databaseNames.filter(name => {
    //   if (name.indexOf(databaseNamePrefix) === -1) return false
    //   const [prefix, id, time] = name.split('_')
    //   if (prefix !== databaseNamePrefix || !id || !time) return true
    //   if (localStorageDiscardedDBList.includes(id)) return true
    //   if (now - (+time) >= 1000 * 60 * 60 * 12) return true
    //   return false
    // })
    // for (const name of discardedDBNames) Dexie.delete(name)
    // localStorage.removeItem(LOCALSTORAGE_KEY_DISCARDED_DB)
};
class PPTistDB extends Dexie {
    snapshots;
    writingBoardImgs;
    constructor() {
        // userId: 0 临时用户 未登录, 使用临时数据库处理
        const userStore = JSON.parse(localStorage.getItem('user') || '{ "userInfo": { "userId": 0 } }');
        super(`${databaseNamePrefix}_${userStore.userInfo.userId || 'temp'}`);
        this.version(1).stores({
            snapshots: '++id',
            writingBoardImgs: '++id',
        });
        this.snapshots = this.table('snapshots');
        this.writingBoardImgs = this.table('writingBoardImgs');
        // 生成内存快照
        // this.snapshots.toArray().then(snapshots => {
        //   this.memorySnapshots = snapshots
        // })
        // this.writingBoardImgs.toArray().then(writingBoardImgs => {
        //   this.memoryWritingBoardImgs = writingBoardImgs
        // })
    }
}
// 幻灯片快照内存表
const snapshots = {
    key: 'index',
    data: [],
    get(id) {
        return this.data.find(item => item.index === id);
    },
    add(item) {
        this.data.push(item);
    },
    update(id, item) {
        const index = this.data.findIndex(item => item.index === id);
        if (index !== -1)
            this.data[index] = item;
    },
    delete(id) {
        const index = this.data.findIndex(item => item.index === id);
        if (index !== -1)
            this.data.splice(index, 1);
    },
    bulkDelete(ids) {
        this.data = this.data.filter(item => !ids.includes(item.index));
    },
    toArray() {
        return Promise.resolve(JSON.parse(JSON.stringify(this.data)));
    },
    orderBy(key) {
        this.data.sort((a, b) => a[key] - b[key]);
        return this;
    },
    keys() {
        return Promise.resolve(this.data.map(item => item[this.key]));
    },
    where(key) {
        return Promise.resolve(this.data.map(item => item[key]));
    },
    equals(key, value) {
        return Promise.resolve(this.data.filter(item => item[key] === value));
    }
};
// 写字板图片内存表
const writingBoardImgs = {
    key: 'id',
    data: [],
    get(id) {
        return this.data.find(item => item[this.key] === id);
    },
    add(item) {
        this.data.push(item);
    },
    update(id, item) {
        const index = this.data.findIndex(item => item[this.key] === id);
        if (index !== -1)
            this.data[index] = item;
    },
    delete(id) {
        const index = this.data.findIndex(item => item[this.key] === id);
        if (index !== -1)
            this.data.splice(index, 1);
    },
    bulkDelete(ids) {
        this.data = this.data.filter(item => !ids.includes(item[this.key]));
    },
    toArray() {
        return Promise.resolve(JSON.parse(JSON.stringify(this.data)));
    },
    orderBy(key) {
        this.data.sort((a, b) => a[key] - b[key]);
        return this;
    },
    keys() {
        return Promise.resolve(this.data.map(item => item[this.key]));
    },
    where(key) {
        return Promise.resolve(this.data.map(item => item[key]));
    },
    equals(key, value) {
        return Promise.resolve(this.data.filter(item => item[key] === value));
    }
};
class MemoryDatabase {
    snapshots = snapshots;
    writingBoardImgs = writingBoardImgs;
    // TODO 刷新环形队列时钟, 用于优化性能
    refreshQueueClock = [];
    constructor() {
        const db = new PPTistDB();
        // 读取快照记录和图片记录
        Promise.all([
            db.table('snapshots').toArray(),
            db.table('writingBoardImgs').toArray()
        ]).then(([snapshots, writingBoardImgs]) => {
            this.snapshots.data = snapshots;
            this.writingBoardImgs.data = writingBoardImgs;
            console.log('内存数据库初始化完成 snapshots size ', this.snapshots.data.length);
            console.log('内存数据库初始化完成 writingBoardImgs size ', this.writingBoardImgs.data.length);
            db.close();
        }).catch(err => {
            console.error(err);
        });
    }
}
// export const db = new MemoryDatabase()
export const db = new PPTistDB();
