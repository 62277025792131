import axios from 'axios';
import { useUserStore } from '@/store';
import { usePlatformStore } from '@/store/platform';
import router from '@/router';
const catchError = (data) => {
    const respKeys = Object.keys(data);
    // 标准平台的错误处理
    const standardPlatformKeys = ['code', 'message'];
    const isStandardPlatform = standardPlatformKeys.every((key) => respKeys.includes(key));
    // 非标准平台的错误处理
    const notStandardPlatformKeys = ['error', 'error_description'];
    const isNotStandardPlatform = notStandardPlatformKeys.every((key) => respKeys.includes(key));
    // oa
    const oaStandardPlatformKeys = ['error', 'status', 'message'];
    const isOaStandardPlatform = oaStandardPlatformKeys.every((key) => respKeys.includes(key));
    if (isStandardPlatform && data.code !== 200) {
        throw Error(data.message, { cause: data });
    }
    else if (isNotStandardPlatform && data.error_description !== '') {
        throw Error(data.error_description, { cause: data });
    }
    else if (isOaStandardPlatform && data.message !== '') {
        throw Error(data.message, { cause: data });
    }
};
const baseURL = '/api';
const axiosInstance = axios.create({
    baseURL,
    timeout: 5 * 60 * 1000,
});
const ignoreContentType = [
    'application/octet-stream',
    'text/plain',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation'
];
// 添加请求拦截器
axiosInstance.interceptors.request.use((config) => {
    let token = '';
    const query = new URLSearchParams(location.search);
    if (query.get('token')) {
        token = query.get('token');
    }
    else {
        token = localStorage.getItem('token');
    }
    // 小程序体验版
    if (query.get('env') === 'release') {
        config.baseURL = '/mall219-api';
    }
    // 如果是素材服务
    if (config.url.startsWith('/pyapi')) {
        config.baseURL = '/';
    }
    if (config.baseURL !== '/oauthapi') {
        config.headers.Authorization = `bearer ${token}`;
    }
    console.log('axios.interceptors.request', config.baseURL, baseURL);
    return config;
});
// 添加响应拦截器
axiosInstance.interceptors.response.use((response) => {
    const userStore = useUserStore();
    const platformStore = usePlatformStore();
    const { data, status } = response;
    if (ignoreContentType.includes(response.headers['Content-Type'] ?? '')) {
        return Promise.resolve(response);
    }
    if (status === 401) {
        userStore.setToken('');
        if (window.uni && !platformStore.isPC) {
            window.uni.redirectTo({
                url: '/pages_three_level/login/login',
            });
        }
        else {
            router.push({
                name: 'Login',
            });
        }
        throw Error('暂无权限，请重新登录');
    }
    if (status >= 500) {
        throw Error('服务异常，请稍后重试');
    }
    catchError(data || {});
    return Promise.resolve(response);
}, (error) => {
    // 兼容服务错误响应处理
    const userStore = useUserStore();
    const platformStore = usePlatformStore();
    const { response } = error;
    if (response) {
        const { data, status } = response;
        if (status === 401) {
            userStore.setToken('');
            // 如果是uni, 跳转到登录页
            if (window.uni && !platformStore.isPC) {
                window.uni.redirectTo({
                    url: '/pages_three_level/login/login',
                });
            }
            else {
                router.push({
                    name: 'Login',
                });
            }
            throw Error('暂无权限，请重新登录');
        }
        if (status >= 500) {
            throw Error('服务异常，请稍后重试');
        }
        catchError(data || {});
        return Promise.reject(response);
    }
    return Promise.reject(error);
});
export default axiosInstance;
