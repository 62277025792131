import { padStart } from 'lodash';
/**
 * 补足数字位数
 * @param digit 数字
 * @param len 位数
 */
export const fillDigit = (digit, len) => {
    return padStart('' + digit, len, '0');
};
/**
 * 判断设备
 */
export const isPC = () => {
    // 如果窗口大小小于750，则是移动端。解决用微信浏览器打开小程序展示判断逻辑
    if (window.innerWidth <= 750) {
        return false;
    }
    return !navigator.userAgent.match(/(iPhone|iPod|iPad|Android|Mobile|BlackBerry|Symbian|Windows Phone)/i);
};
/**
 * 实现lodash.filter版本的对象或数组过滤
 * @param {*} params 要过滤的入参对象
 * @param {*} callback 过滤的回调函数
 * @returns
 */
export function filter(params, callback) {
    if (typeof callback !== 'function') {
        callback = x => x;
    }
    if (Array.isArray(params)) {
        return params.filter(callback);
    }
    if (params) {
        const ownKeys = Object.getOwnPropertyNames(params);
        const filterOwnKeys = ownKeys.filter((key) => callback({ value: params[key], key }));
        for (const key of ownKeys) {
            const hasKey = filterOwnKeys.some(filterKey => key === filterKey);
            if (hasKey) {
                continue;
            }
            delete params[key];
        }
    }
    return params;
}
/**
 * 下载文件
 * @param url
 * @param filename
 */
export function downloadFile(url, filename) {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.responseType = 'blob';
        xhr.onload = function () {
            if (xhr.status === 200) {
                const blob = new Blob([xhr.response], { type: 'application/octet-stream' });
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = filename;
                link.click();
                resolve();
            }
        };
        xhr.onerror = reject;
        xhr.send();
    });
}
