import axiosInstance from './axios-instance';
export async function get(url, config) {
    const response = await axiosInstance.get(url, config);
    return response.data;
}
export async function post(url, data, config) {
    const response = await axiosInstance.post(url, data, config);
    return response.data;
}
export async function put(url, data, config) {
    const response = await axiosInstance.put(url, data, config);
    return response.data;
}
export async function del(url, params) {
    const response = await axiosInstance.delete(url, { params });
    return response.data;
}
// import buildURL from 'axios/lib/helpers/buildURL'
export const buildURL = (url, params) => {
    const parts = [];
    Object.keys(params).forEach((key) => {
        let val = params[key];
        if (val === null || typeof val === 'undefined') {
            return;
        }
        if (Array.isArray(val)) {
            key += '[]';
        }
        if (val instanceof Date) {
            val = val.toISOString();
        }
        else if (val !== null && typeof val === 'object') {
            val = JSON.stringify(val);
        }
        parts.push(`${encodeURIComponent(key)}=${encodeURIComponent(val)}`);
    });
    return `${url}${parts.length ? `?${parts.join('&')}` : ''}`;
};
