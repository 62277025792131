import { defineStore } from 'pinia';
import { useSlidesStore, useMainStore } from '@/store';
import useSlideHandler from '@/hooks/useSlideHandler';
import { THEME_COLOR_LIST } from '@/configs/theme';
export const usePPTStore = defineStore('ppt', {
    state: () => ({
        pptLog: {
            recordId: '',
            poster: '',
            title: '',
        },
        template: {
            id: undefined,
            styleId: undefined,
            name: '',
            tempUrl: '',
            themeColor: THEME_COLOR_LIST[0].value,
            themeDesc: '',
            themeKeywords: '',
            poster: '',
            slides: [],
        },
        // 生成记录列表，用于重新生成ppt
        // 给ppt编辑人员使用
        recordList: [],
    }),
    actions: {
        setPPTLog(state) {
            this.pptLog = state;
        },
        setTemplateInfo(state) {
            const mainStore = useMainStore();
            const slidesStore = useSlidesStore();
            const { appendSlideStyle } = useSlideHandler();
            mainStore.setActiveElementIdList([]);
            appendSlideStyle(state.slides || []);
            slidesStore.setSlides(state.slides || []);
            slidesStore.updateSlideIndex(0);
            slidesStore.setFileName(state.name);
            this.template = state;
            this.template.themeColor = state.themeColor || THEME_COLOR_LIST[0].value;
            this._setSlides(state.slides || []);
        },
        // 给slides.ts用的，用于同步slides的引用地址，对store外部私有
        _setSlides(slides) {
            this.template.slides = slides;
        },
        // 添加生成记录
        addRecord(record) {
            this.recordList.unshift(record);
        },
        // 清空生成记录
        clearRecord() {
            this.recordList = [];
        },
        // 删除生成记录
        deleteRecord(id) {
            const index = this.recordList.findIndex(item => item.pptId === id);
            this.recordList.splice(index, 1);
        },
    },
    persist: {
        key: 'ppt',
    },
});
