/**
 * 将普通文本转为带段落信息的HTML字符串
 * @param text 文本
 */
export const parseText2Paragraphs = (text) => {
    const htmlText = text.replace(/[\n\r]+/g, '<br>');
    const paragraphs = htmlText.split('<br>');
    let string = '';
    for (const paragraph of paragraphs) {
        if (paragraph)
            string += `<div>${paragraph}</div>`;
    }
    return string;
};
/**
 * 将小驼峰转化为下/中划线
 * @param text 文本
 * @param type 默认中划线
 */
export const toLine = (text, type = '-') => {
    return text.replace(/([A-Z])/g, type + '$1').toLowerCase();
};
