export const THEME_COLOR_LIST = [
    {
        id: -1,
        value: '',
    },
    {
        id: 1,
        value: '#5C8DF6',
    },
    {
        id: 2,
        value: '#F3628D',
    },
    {
        id: 3,
        value: '#454B57',
    },
    {
        id: 4,
        value: '#CFD9ED',
    },
    {
        id: 5,
        value: '#C20000',
    },
    {
        id: 6,
        value: '#DA96A2',
    },
    {
        id: 7,
        value: '#64C3C1',
    },
    {
        id: 8,
        value: '#5C47C2',
    },
    {
        id: 9,
        value: '#489DBA',
    },
    {
        id: 10,
        value: '#E5E6E6',
    },
    {
        id: 11,
        value: '#f48618',
    }
];
export const PRESET_THEMES = [
    {
        background: '#ffffff',
        fontColor: '#333333',
        fontname: 'Microsoft Yahei',
        colors: ['#5b9bd5', '#ed7d31', '#a5a5a5', '#ffc000', '#4472c4', '#70ad47'],
    },
    {
        background: '#ffffff',
        fontColor: '#333333',
        fontname: 'Microsoft Yahei',
        colors: ['#83992a', '#3c9670', '#44709d', '#a23b32', '#d87728', '#deb340'],
    },
    {
        background: '#ffffff',
        fontColor: '#333333',
        fontname: 'Microsoft Yahei',
        colors: ['#e48312', '#bd582c', '#865640', '#9b8357', '#c2bc80', '#94a088'],
    },
    {
        background: '#ffffff',
        fontColor: '#333333',
        fontname: 'Microsoft Yahei',
        colors: ['#bdc8df', '#003fa9', '#f5ba00', '#ff7567', '#7676d9', '#923ffc'],
    },
    {
        background: '#ffffff',
        fontColor: '#333333',
        fontname: 'Microsoft Yahei',
        colors: ['#90c225', '#54a121', '#e6b91e', '#e86618', '#c42f19', '#918756'],
    },
    {
        background: '#ffffff',
        fontColor: '#333333',
        fontname: 'Microsoft Yahei',
        colors: ['#1cade4', '#2683c6', '#27ced7', '#42ba97', '#3e8853', '#62a39f'],
    },
    {
        background: '#e9efd6',
        fontColor: '#333333',
        fontname: 'Microsoft Yahei',
        colors: ['#a5300f', '#de7e18', '#9f8351', '#728653', '#92aa4c', '#6aac91'],
    },
    {
        background: '#17444e',
        fontColor: '#ffffff',
        fontname: 'Microsoft Yahei',
        colors: ['#b01513', '#ea6312', '#e6b729', '#6bab90', '#55839a', '#9e5d9d'],
    },
    {
        background: '#36234d',
        fontColor: '#ffffff',
        fontname: 'Microsoft Yahei',
        colors: ['#b31166', '#e33d6f', '#e45f3c', '#e9943a', '#9b6bf2', '#d63cd0'],
    },
    {
        background: '#247fad',
        fontColor: '#ffffff',
        fontname: 'Microsoft Yahei',
        colors: ['#052f61', '#a50e82', '#14967c', '#6a9e1f', '#e87d37', '#c62324'],
    },
    {
        background: '#103f55',
        fontColor: '#ffffff',
        fontname: 'Microsoft Yahei',
        colors: ['#40aebd', '#97e8d5', '#a1cf49', '#628f3e', '#f2df3a', '#fcb01c'],
    },
    {
        background: '#242367',
        fontColor: '#ffffff',
        fontname: 'Microsoft Yahei',
        colors: ['#ac3ec1', '#477bd1', '#46b298', '#90ba4c', '#dd9d31', '#e25345'],
    },
    {
        background: '#e4b75e',
        fontColor: '#333333',
        fontname: 'Microsoft Yahei',
        colors: ['#f0a22e', '#a5644e', '#b58b80', '#c3986d', '#a19574', '#c17529'],
    },
    {
        background: '#333333',
        fontColor: '#ffffff',
        fontname: 'Microsoft Yahei',
        colors: ['#bdc8df', '#003fa9', '#f5ba00', '#ff7567', '#7676d9', '#923ffc'],
    },
];
