import { storeToRefs } from 'pinia';
import { nanoid } from 'nanoid';
import { useSlidesStore, useMainStore } from '@/store';
import { createSlideIdMap, createElementIdMap, getElementRange } from '@/utils/element';
import useHistorySnapshot from '@/hooks/useHistorySnapshot';
export default () => {
    const mainStore = useMainStore();
    const slidesStore = useSlidesStore();
    const { currentSlide } = storeToRefs(slidesStore);
    const { addHistorySnapshot } = useHistorySnapshot();
    /**
     * 添加指定的元素数据（一组）
     * @param elements 元素列表数据
     */
    const addElementsFromData = (elements) => {
        const { groupIdMap, elIdMap } = createElementIdMap(elements);
        const firstElement = elements[0];
        let offset = 0;
        let lastSameElement;
        do {
            lastSameElement = currentSlide.value.elements.find(el => {
                if (el.type !== firstElement.type)
                    return false;
                const { minX: oMinX, maxX: oMaxX, minY: oMinY, maxY: oMaxY } = getElementRange(el);
                const { minX: nMinX, maxX: nMaxX, minY: nMinY, maxY: nMaxY } = getElementRange({
                    ...firstElement,
                    left: firstElement.left + offset,
                    top: firstElement.top + offset
                });
                if (oMinX === nMinX &&
                    oMaxX === nMaxX &&
                    oMinY === nMinY &&
                    oMaxY === nMaxY)
                    return true;
                return false;
            });
            if (lastSameElement)
                offset += 10;
        } while (lastSameElement);
        for (const element of elements) {
            element.id = elIdMap[element.id];
            element.left = element.left + offset;
            element.top = element.top + offset;
            if (element.groupId)
                element.groupId = groupIdMap[element.groupId];
        }
        slidesStore.addElement(elements);
        mainStore.setActiveElementIdList(Object.values(elIdMap));
        addHistorySnapshot();
    };
    /**
     * 添加指定的页面数据
     * @param slide 页面数据
     */
    const addSlidesFromData = (slides) => {
        const slideIdMap = createSlideIdMap(slides);
        const newSlides = slides.map(slide => {
            const { groupIdMap, elIdMap } = createElementIdMap(slide.elements);
            for (const element of slide.elements) {
                element.id = elIdMap[element.id];
                if (element.groupId)
                    element.groupId = groupIdMap[element.groupId];
                // 若元素绑定了页面跳转链接
                if (element.link && element.link.type === 'slide') {
                    // 待添加页面中包含该页面，则替换相关绑定关系
                    if (slideIdMap[element.link.target]) {
                        element.link.target = slideIdMap[element.link.target];
                    }
                    // 待添加页面中不包含该页面，则删除该元素绑定的页面跳转
                    else
                        delete element.link;
                }
            }
            // 动画id替换
            if (slide.animations) {
                for (const animation of slide.animations) {
                    animation.id = nanoid(10);
                    animation.elId = elIdMap[animation.elId];
                }
            }
            return {
                ...slide,
                id: slideIdMap[slide.id],
            };
        });
        slidesStore.addSlide(newSlides);
        addHistorySnapshot();
    };
    return {
        addElementsFromData,
        addSlidesFromData,
    };
};
