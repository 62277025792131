import { inputRules, wrappingInputRule, textblockTypeInputRule, smartQuotes, emDash, ellipsis, InputRule } from 'prosemirror-inputrules';
const blockQuoteRule = (nodeType) => wrappingInputRule(/^\s*>\s$/, nodeType);
const orderedListRule = (nodeType) => (wrappingInputRule(/^(\d+)\.\s$/, nodeType, match => ({ order: +match[1] }), (match, node) => node.childCount + node.attrs.order === +match[1]));
const linkRule = () => {
    const urlRegEx = /(?:https?:\/\/)?[\w-]+(?:\.[\w-]+)+\.?(?:\d+)?(?:\/\S*)?$/;
    return new InputRule(urlRegEx, (state, match, start, end) => {
        const { schema } = state;
        const tr = state.tr.insertText(match[0], start, end);
        const mark = schema.marks.link.create({ href: match[0], title: match[0] });
        return tr.addMark(start, start + match[0].length, mark);
    });
};
const bulletListRule = (nodeType) => wrappingInputRule(/^\s*([-+*])\s$/, nodeType);
const codeBlockRule = (nodeType) => textblockTypeInputRule(/^```$/, nodeType);
export const buildInputRules = (schema) => {
    const rules = [
        ...smartQuotes,
        ellipsis,
        emDash,
    ];
    rules.push(blockQuoteRule(schema.nodes.blockquote));
    rules.push(orderedListRule(schema.nodes.ordered_list));
    rules.push(bulletListRule(schema.nodes.bullet_list));
    rules.push(codeBlockRule(schema.nodes.code_block));
    rules.push(linkRule());
    return inputRules({ rules });
};
