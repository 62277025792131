import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { nanoid } from 'nanoid';
import { useMainStore, useSlidesStore } from '@/store';
import { copyText, readClipboard } from '@/utils/clipboard';
import { encrypt } from '@/utils/crypto';
import { createElementIdMap, createStyle } from '@/utils/element';
import { KEYS } from '@/configs/hotkey';
import { message } from 'ant-design-vue';
import usePasteTextClipboardData from '@/hooks/usePasteTextClipboardData';
import useHistorySnapshot from '@/hooks/useHistorySnapshot';
import useAddSlidesOrElements from '@/hooks//useAddSlidesOrElements';
import { initProsemirrorEditor } from '@/utils/prosemirror';
import { post } from '@/utils/api';
import { base64ToFile } from '@/utils/base64';
import { svg2Base64 } from '@/utils/svg2Base64';
import { isNull, isUndefined } from 'lodash';
export default () => {
    const mainStore = useMainStore();
    const slidesStore = useSlidesStore();
    const { selectedSlidesIndex: _selectedSlidesIndex, activeElementIdList } = storeToRefs(mainStore);
    const { currentSlide, slides, theme, slideIndex } = storeToRefs(slidesStore);
    const selectedSlidesIndex = computed(() => [..._selectedSlidesIndex.value, slideIndex.value]);
    const selectedSlides = computed(() => slides.value.filter((item, index) => selectedSlidesIndex.value.includes(index)));
    const selectedSlidesId = computed(() => selectedSlides.value.map(item => item.id));
    const { pasteTextClipboardData } = usePasteTextClipboardData();
    const { addSlidesFromData } = useAddSlidesOrElements();
    const { addHistorySnapshot } = useHistorySnapshot();
    // 重置幻灯片
    const resetSlides = () => {
        const emptySlide = {
            id: nanoid(10),
            elements: [],
            background: {
                type: 'solid',
                color: theme.value.backgroundColor,
            },
        };
        slidesStore.updateSlideIndex(0);
        mainStore.setActiveElementIdList([]);
        slidesStore.setSlides([emptySlide]);
    };
    // 将slides中所有base64图片上传并回写src
    const batchPostBase64 = async () => {
        const fileList = [];
        const formData = new FormData();
        for (const slide of slides.value) {
            const isBase64Src = slide.background?.image?.startsWith('data:image');
            const isImageType = ['image', 'solid', 'gradient'].includes(slide.background?.type);
            if (isImageType && isBase64Src) {
                const ext = slide.background?.image?.split(';')[0].split('/')[1];
                const mineType = slide.background?.image?.split(';')[0].split(':')[1];
                if (ext && mineType && slide.background?.image) {
                    fileList.push(base64ToFile(slide.background?.image, `${slide.id}.${ext}`, mineType));
                }
            }
            // 查找slide.elements中的图片
            for (const element of slide.elements) {
                if (element.type === 'image') {
                    const isBase64Src = element.src?.startsWith('data:image');
                    if (!isBase64Src) {
                        continue;
                    }
                    // 获取base64图片扩展类型
                    const ext = element.src?.split(';')[0].split('/')[1];
                    // 获取mine类型
                    const mineType = element.src?.split(';')[0].split(':')[1];
                    if (ext && mineType) {
                        fileList.push(base64ToFile(element.src, `${element.id}.${ext}`, mineType));
                    }
                }
                // 如果是需要转成图片的svg资源，并且没有中文字符
                if (element.type === 'shape' && element.special) {
                    const hasZhContent = element.text?.content?.match(/[\u4e00-\u9fa5]/g);
                    if (hasZhContent) {
                        continue;
                    }
                    const svgRef = document.querySelector(`.thumbnail-list .base-element-${element.id} svg`);
                    if (svgRef) {
                        const base64SVG = await svg2Base64(svgRef, true, { canvas: {
                                width: element.width,
                                height: element.height,
                            } });
                        console.log('base64SVG', base64SVG);
                        // 获取base64图片扩展类型
                        const ext = base64SVG.split(';')[0].split('/')[1];
                        // 获取mine类型
                        const mineType = base64SVG.split(';')[0].split(':')[1];
                        if (ext && mineType) {
                            console.log('base64SVG', base64SVG);
                            fileList.push(base64ToFile(base64SVG, `${element.id}.${ext}`, mineType));
                        }
                    }
                }
            }
        }
        fileList.forEach((file) => {
            formData.append('multipartFiles', file);
        });
        let res = {
            data: []
        };
        if (fileList.length) {
            res = await post('/common/upload/batch', formData, {
            // baseURL: process.env.NODE_ENV === 'development' ? '/local' : '/api',
            });
        }
        if (fileList.length && res.data && res.data.length !== fileList.length) {
            throw Error('图片解析上传失败');
        }
        const fileNameList = (res.data || []).map((src) => {
            return {
                src,
                url: src.substring(src.lastIndexOf('/') + 1)
            };
        });
        // 回写fileNameList的src到slides中
        for (const slide of slides.value) {
            const isBase64Src = slide.background?.image?.startsWith('data:image');
            const isImageType = ['image', 'solid', 'gradient'].includes(slide.background?.type);
            if (isImageType && isBase64Src) {
                const fileName = fileNameList.find((item) => item.url.search(slide.id + '_') === 0);
                slide.background.image = fileName?.src || slide.background.image;
                slide.background.type = 'image';
            }
            for (const element of slide.elements) {
                if (element.type === 'image') {
                    const fileName = fileNameList.find((item) => item.url.search(element.id + '_') === 0);
                    element.src = fileName?.src || element.src;
                }
                // 将element.svg转换成图片
                if (element.type === 'shape' && element.special) {
                    const hasZhContent = element.text?.content?.match(/[\u4e00-\u9fa5]/g);
                    if (hasZhContent) {
                        continue;
                    }
                    const fileName = fileNameList.find((item) => item.url.search(element.id + '_') === 0);
                    console.log('element', element);
                    element.src = fileName?.src || element.src;
                    element.fixedRatio = true;
                    element.type = 'image';
                }
            }
        }
        console.log('fileNameList', fileNameList);
    };
    /**
     * 验证标记内容
     * @param type 标记类型
     * @param index 幻灯片下表
     * @param limitCount 标记类型最大限制数
     * @param markCount 已标记数
     */
    const validateMarkElement = (type, index, limitCount, markCount) => {
        if (markCount > limitCount) {
            throw Error(`第${index}页幻灯片${type}标记数超过排版限定数: ${limitCount}，请删除超出的标记`);
        }
        else if (markCount < limitCount) {
            throw Error(`第${index}页幻灯片${type}标记数小于排版限定数: ${limitCount}，请新增对应的标记`);
        }
    };
    /**
     * 验证幻灯片标记是否标记正确
     * @param slides
     */
    const validateSlidesMark = (slides) => {
        if (slides.length < 1) {
            throw Error(`请先生成幻灯片`);
        }
        for (let i = 0, size = slides.length; i < size; i++) {
            const slide = slides[i];
            const slideIndex = i + 1;
            if (!slide) {
                continue;
            }
            const { slideType, slideInfoAmount } = slide;
            // 幻灯片类型是否为空
            if (isUndefined(slideType) || isNull(slideType)) {
                throw Error(`请设置第${slideIndex}页幻灯片的排版类型`);
            }
            // slideType: 1. 内容排版  3. 目录排版 7. 标题内容混排
            if ((slideType === 1 || slideType === 3 || slideType === 7) &&
                (isUndefined(slideInfoAmount) || isNull(slideInfoAmount))) {
                throw Error(`请设置第${slideIndex}页幻灯片的排版数量`);
            }
            const markContentCount = slide.elements.filter(x => x.type === 'text' && x.mark === 'content').length;
            const markTitleCount = slide.elements.filter(x => x.type === 'text' && x.mark === 'title').length;
            // TODO: 如果标记了副标题或者索引, 提示报错， 后续需要删除
            const markSubTitleCount = slide.elements.filter(x => x.type === 'text' && x.mark === 'subtitle').length;
            const markIndexCount = slide.elements.filter(x => x.type === 'text' && x.mark === 'index').length;
            if (markSubTitleCount > 0) {
                throw Error(`暂不支持副标题标记功能，请删除${slideIndex}页幻灯片无用的副标题标记数(${markSubTitleCount})`);
            }
            else if (markIndexCount > 0) {
                throw Error(`暂不支持索引标记功能，请删除${slideIndex}页幻灯片无用的索引标记数(${markIndexCount})`);
            }
            if (slideType === 1) {
                validateMarkElement('内容排版', slideIndex, slideInfoAmount, markContentCount);
            }
            else if (slideType === 2) {
                validateMarkElement('标题排版', slideIndex, 1, markTitleCount);
            }
            else if (slideType === 3) {
                validateMarkElement('目录排版', slideIndex, slideInfoAmount, markTitleCount);
            }
            else if (slideType === 4) {
                validateMarkElement('首页排版-标题标记', slideIndex, 1, markTitleCount);
                validateMarkElement('首页排版-内容标记', slideIndex, 0, markContentCount);
            }
            else if (slideType === 5) {
                validateMarkElement('致谢页排版-标题标记', slideIndex, 0, markTitleCount);
                validateMarkElement('致谢页排版-内容标记', slideIndex, 0, markContentCount);
            }
            else if (slideType === 6) {
                validateMarkElement('标题页排版', slideIndex, 1, markTitleCount);
            }
            else if (slideType === 7) {
                validateMarkElement('标题内容混排-标题标记', slideIndex, slideInfoAmount, markTitleCount);
                validateMarkElement('标题内容混排-内容标记', slideIndex, slideInfoAmount, markContentCount);
            }
        }
    };
    /**
     * 移动页面焦点
     * @param command 移动页面焦点命令：上移、下移
     */
    const updateSlideIndex = (command) => {
        if (command === KEYS.UP && slideIndex.value > 0) {
            if (activeElementIdList.value.length)
                mainStore.setActiveElementIdList([]);
            slidesStore.updateSlideIndex(slideIndex.value - 1);
        }
        else if (command === KEYS.DOWN && slideIndex.value < slides.value.length - 1) {
            if (activeElementIdList.value.length)
                mainStore.setActiveElementIdList([]);
            slidesStore.updateSlideIndex(slideIndex.value + 1);
        }
    };
    // 将当前页面数据加密后复制到剪贴板
    const copySlide = () => {
        const text = encrypt(JSON.stringify({
            type: 'slides',
            data: selectedSlides.value,
        }));
        copyText(text).then(() => {
            mainStore.setThumbnailsFocus(true);
        });
    };
    // 尝试将剪贴板页面数据解密后添加到下一页（粘贴）
    const pasteSlide = () => {
        readClipboard().then(text => {
            pasteTextClipboardData(text, { onlySlide: true });
        }).catch(err => message.warning(err));
    };
    // 创建一页空白页并添加到下一页
    const createSlide = () => {
        const emptySlide = {
            id: nanoid(10),
            elements: [],
            background: {
                type: 'solid',
                color: theme.value.backgroundColor,
            },
        };
        mainStore.setActiveElementIdList([]);
        slidesStore.addSlide(emptySlide);
        addHistorySnapshot();
    };
    // 根据模板创建新页面
    const createSlideByTemplate = (slide) => {
        const { groupIdMap, elIdMap } = createElementIdMap(slide.elements);
        for (const element of slide.elements) {
            element.id = elIdMap[element.id];
            if (element.groupId)
                element.groupId = groupIdMap[element.groupId];
        }
        const newSlide = {
            ...slide,
            id: nanoid(10),
        };
        mainStore.setActiveElementIdList([]);
        slidesStore.addSlide(newSlide);
        addHistorySnapshot();
    };
    // 将当前页复制一份到下一页
    const copyAndPasteSlide = () => {
        const slide = JSON.parse(JSON.stringify(currentSlide.value));
        addSlidesFromData([slide]);
    };
    // 删除当前页，若将删除全部页面，则执行重置幻灯片操作
    const deleteSlide = (targetSlidesId = selectedSlidesId.value) => {
        if (slides.value.length === targetSlidesId.length)
            resetSlides();
        else
            slidesStore.deleteSlide(targetSlidesId);
        mainStore.updateSelectedSlidesIndex([]);
        addHistorySnapshot();
    };
    // 将当前页复制后删除（剪切）
    // 由于复制操作会导致多选状态消失，所以需要提前将需要删除的页面ID进行缓存
    const cutSlide = () => {
        const targetSlidesId = [...selectedSlidesId.value];
        copySlide();
        deleteSlide(targetSlidesId);
    };
    // 选中全部幻灯片
    const selectAllSlide = () => {
        const newSelectedSlidesIndex = Array.from(Array(slides.value.length), (item, index) => index);
        mainStore.setActiveElementIdList([]);
        mainStore.updateSelectedSlidesIndex(newSelectedSlidesIndex);
    };
    // 拖拽调整幻灯片顺序同步数据
    const sortSlides = (newIndex, oldIndex) => {
        if (oldIndex === newIndex)
            return;
        const _slides = JSON.parse(JSON.stringify(slides.value));
        const _slide = _slides[oldIndex];
        _slides.splice(oldIndex, 1);
        _slides.splice(newIndex, 0, _slide);
        slidesStore.setSlides(_slides);
        slidesStore.updateSlideIndex(newIndex);
    };
    // 追加幻灯片内联样式
    const appendSlideStyle = (slides) => {
        for (const slide of slides) {
            for (const element of slide.elements) {
                const hasHtml = !/<[^>]*>/g.test(element.content);
                const isText = element.type === 'text' && typeof element.style === 'string';
                if (isText && hasHtml) {
                    // 生成style样式
                    const style = JSON.parse(element.style ?? '{}');
                    const p = createStyle(style);
                    // 将style样式转换为dom-tree结构，让首次导入时的dom结构跟绘图时的dom结构保持一致
                    p.innerText = element.content;
                    const tempEditor = initProsemirrorEditor(p, p.outerHTML);
                    element.content = tempEditor.dom.innerHTML;
                    tempEditor.destroy();
                }
            }
        }
    };
    return {
        resetSlides,
        appendSlideStyle,
        updateSlideIndex,
        copySlide,
        pasteSlide,
        createSlide,
        createSlideByTemplate,
        copyAndPasteSlide,
        deleteSlide,
        cutSlide,
        selectAllSlide,
        sortSlides,
        batchPostBase64,
        validateSlidesMark
    };
};
