export var ToolbarStates;
(function (ToolbarStates) {
    ToolbarStates["SYMBOL"] = "symbol";
    ToolbarStates["EL_ANIMATION"] = "elAnimation";
    ToolbarStates["EL_STYLE"] = "elStyle";
    ToolbarStates["EL_POSITION"] = "elPosition";
    ToolbarStates["SLIDE_DESIGN"] = "slideDesign";
    ToolbarStates["SLIDE_ANIMATION"] = "slideAnimation";
    ToolbarStates["MULTI_POSITION"] = "multiPosition";
})(ToolbarStates || (ToolbarStates = {}));
