import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import './registerServiceWorker';
import PiniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import '@icon-park/vue-next/styles/index.css';
import 'prosemirror-view/style/prosemirror.css';
import 'animate.css';
import '@/assets/styles/prosemirror.scss';
import '@/assets/styles/global.scss';
import '@/assets/styles/antd.scss';
import '@/assets/styles/font.scss';
import '@/assets/styles/uview.scss';
import Icon from '@/plugins/icon';
import Directive from '@/plugins/directive';
import axios from 'axios';
import VueAxios from 'vue-axios';
import router from './router/index';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import Vant from 'vant';
import 'vant/lib/index.css';
const app = createApp(App);
const pinia = createPinia();
pinia.use(PiniaPluginPersistedstate);
app.use(Icon);
app.use(Directive);
app.use(VueAxios, axios);
app.use(ElementPlus);
app.use(Vant);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component);
}
app.use(router);
app.use(pinia);
router.isReady().then(() => {
    app.mount('#app');
});
