import { defineStore } from 'pinia';
export const useUserStore = defineStore('user', {
    state: () => ({
        token: '',
        userInfo: {
            userId: 0,
            username: '',
            mobile: '',
            nickName: '',
        },
    }),
    getters: {
        isLogin(state) {
            return !!state.token;
        }
    },
    actions: {
        setToken(token) {
            this.token = token;
            localStorage.setItem('token', token);
        },
        setUserInfo(userInfo) {
            this.userInfo = userInfo;
        },
    },
    persist: {
        key: 'user',
    },
});
