import { pasteCustomClipboardString } from '@/utils/clipboard';
import { parseText2Paragraphs } from '@/utils/textParser';
import useCreateElement from '@/hooks/useCreateElement';
import useAddSlidesOrElements from '@/hooks/useAddSlidesOrElements';
export default () => {
    const { createTextElement } = useCreateElement();
    const { addElementsFromData, addSlidesFromData } = useAddSlidesOrElements();
    /**
     * 粘贴普通文本：创建为新的文本元素
     * @param text 文本
     */
    const createTextElementFromClipboard = (text) => {
        createTextElement({
            left: 0,
            top: 0,
            width: 600,
            height: 50,
        }, { content: text });
    };
    /**
     * 解析剪贴板内容，根据解析结果选择合适的粘贴方式
     * @param text 剪贴板内容
     * @param options 配置项：onlySlide -- 仅处理页面粘贴；onlyElements -- 仅处理元素粘贴；
     */
    const pasteTextClipboardData = (text, options) => {
        const onlySlide = options?.onlySlide || false;
        const onlyElements = options?.onlyElements || false;
        const clipboardData = pasteCustomClipboardString(text);
        // 元素或页面
        if (typeof clipboardData === 'object') {
            const { type, data } = clipboardData;
            if (type === 'elements' && !onlySlide)
                addElementsFromData(data);
            else if (type === 'slides' && !onlyElements)
                addSlidesFromData(data);
        }
        // 普通文本
        else if (!onlyElements && !onlySlide) {
            const string = parseText2Paragraphs(clipboardData);
            createTextElementFromClipboard(string);
        }
    };
    return {
        pasteTextClipboardData,
    };
};
