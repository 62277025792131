import { createRouter, createWebHistory } from 'vue-router';
// 2. 配置路由
const routes = [
    {
        path: '/',
        redirect: {
            name: 'Home',
        },
        children: [
            {
                path: '/login',
                name: 'Login',
                meta: {
                    title: '登录'
                },
                component: () => import('@/views/Login/index.vue'),
            },
            {
                path: '/home',
                name: 'Home',
                meta: {
                    title: 'AI PPT'
                },
                component: () => import('@/views/Home/index.vue')
            },
            {
                path: '/ai',
                name: 'AI',
                meta: {
                    title: 'AI生成'
                },
                component: () => import('@/views/AI/index.vue'),
            },
            {
                path: '/editor',
                name: 'PPTEditor',
                meta: {
                    title: 'PPT编辑',
                    permission: [],
                },
                component: () => import('@/views/AI/pptEditor.vue'),
            },
            {
                path: '/maintain',
                name: 'PPTMaintain',
                meta: {
                    title: '素材维护',
                    permission: [],
                },
                component: () => import('@/views/AI/PPTMaintain/index.vue'),
            },
        ]
    },
    {
        path: '/pptGenerating',
        name: 'PPTGenerating',
        component: () => import('@/views/AI/pptGenerating.vue'),
    },
    {
        path: '/temp',
        name: 'Temp',
        component: () => import('@/views/Test/temp.vue'),
    }
];
// 1.返回一个 router 实列，为函数，里面有配置项（对象） history
const router = createRouter({
    history: createWebHistory(),
    routes,
});
// 3导出路由   然后去 main.ts 注册 router.ts
export default router;
