import { defineStore, storeToRefs } from 'pinia';
import { isPC as getAgent } from '@/utils/common';
const usePlatformStore = defineStore('platform', {
    state: () => ({
        isPC: getAgent(),
        permission: []
    }),
});
window.onresize = () => {
    const { isPC } = storeToRefs(usePlatformStore());
    isPC.value = getAgent();
};
window.onload = () => {
    const { isPC } = storeToRefs(usePlatformStore());
    isPC.value = getAgent();
};
export { usePlatformStore };
